import React, { useState, useEffect, useContext } from 'react';
import CookieBannerWrapper from './style';
import { P, Button, Row, Column } from '../../styleguide-remapped/styleguide-remapped';
import CookieUtils from '../../../lib/cookie-util';
import { GlobalContext } from '../../../pages/_app';
import Link from 'next/link';
import { uid } from 'react-uid';

const CookieBanner = ({ }) => {
  const { dataPrivacyUrl } = useContext(GlobalContext);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isCookieExisting = CookieUtils.isCookieExisting();
    setShowBanner(!isCookieExisting);
  }, []);

  const handleAccept = () => {
    CookieUtils.acceptCookies();
    setShowBanner(false);
  };

  const handleDecline = () => {
    CookieUtils.declineCookies();
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  if (!dataPrivacyUrl) {
    return <></>
  }

  return (
    <CookieBannerWrapper className="cookie-banner">
      <Row>
        <Column all={12} xs={12} s={9} m={9} px={0} mx={0} className="primary-link">
          <P>
            Um zu verstehen, wie die Hilfe-Seiten genutzt werden und um Ihnen die relevantesten Informationen präsentieren zu können, nutzen wir Cookies und andere Messverfahren. Sind Sie damit einverstanden?&ensp;
            <Link href={dataPrivacyUrl?.link} target={dataPrivacyUrl?.targetBlank ? '_blank' : '_self'} key={uid(dataPrivacyUrl)}>
              {dataPrivacyUrl?.text}
            </Link>
          </P>
        </Column>
        <Column className="button-row" all={12} xs={12} s={3} m={3} px={0} mx={0}>
          <Row>
            <Button type="secondary" text="Ablehnen" onClick={handleDecline} />
            <Button type="primary" text="Zustimmen" onClick={handleAccept} />
          </Row>
        </Column>
      </Row>
    </CookieBannerWrapper>
  );
};

export default CookieBanner;
